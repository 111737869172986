* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 16px;
}

body {
  box-sizing: border-box;
  font-family: "Poppins", system-ui;
  color: #222;
}

h1 {
  font-size: 2.25rem;
  line-height: 2.25rem;
  margin: 3rem 0;
  font-weight: 600;
}

h2 {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}

h3 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  margin: 3rem 0 1rem;
}

p {
  font-size: 14px;
  margin-bottom: 10px;
}

b {
  font-weight: 700;
}

a {
  font-size: 14px;
  color: rgb(77, 109, 255);
  font-weight: 600;
}

.App {
  width: 100%;
}

.App-main {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  @media screen and (max-width: 600px) {
    min-width: calc(100% - 40px);
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.App-header img:nth-child(2) {
  width: 50px;
  height: 30px;
}

.Container {
  margin: 0 auto;
  min-height: 60vh;
  overflow-y: auto;
  padding: 0 0 3rem;
}

.PrivacyContainer {
  margin: 0 auto;
  height: 100%;
  padding: 10px;
}

.PrivacyTSPContainer {
  margin: 0 auto;
  height: 100%;
  padding: 10px;
}

.TextContainer {
  padding: 0;
}

.VersionTextContent {
  padding: 0;
  text-align: left;
  color: darkslategray;
  font-size: 0.9rem;
}

.DateTextContent {
  padding: 0;
  text-align: right;
  color: darkslategray;
  font-size: 0.9rem;
}

.Logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.Code-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 3rem 0;
  padding: 3rem;
  border: 4px solid #f2f2f2;
}

.Code {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Code img.qr {
  height: 200px;
}

.device-logo {
  max-width: 3rem;
  margin: 1rem 0;
}

.language-toggle {
  display: flex;
  padding: 0.5rem 0.5rem;
  align-items: center;
  box-sizing: border-box;
  height: 4rem;
  border-radius: 2rem;
  background-color: #fff;
  border: 5px solid #eee;

  @media screen and (max-width: 600px) {
    margin-top: 3rem;
  }
}

.language-toggle button {
  background: none;
  padding: 0;
  font-size: 0.8rem;
  color: #242424;
  height: 2.5rem;
  font-weight: 400;
  border: none;
  border-radius: 1.25rem;
  padding: 0.5rem 2rem;
  cursor: pointer;
}

.language-toggle button:hover {
  background-color: rgba(79, 78, 204, 0.1);
}

.language-toggle button.selected {
  background-color: #4f4ecc;
  color: #fff;
  font-weight: 700;
}

.released-version-wrapper {
  padding: 1rem;
  background-color: #f2f2f2;
  border-radius: 0.25rem;
  margin: 1rem 0;
}
