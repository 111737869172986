.footer {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  padding: 2rem;
}

.footer-links {
  display: flex;
  align-items: center;
  list-style: none;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.footer-links li {
  margin: 1rem;
}

.footer-links li a {
  color: #4f4ecc;
  font-size: 1rem;
}

.footer-links li a:hover {
  text-decoration: none;
}

.footer-versions {
  display: flex;
  align-items: center;
  font-size: 0.8rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.footer-versions span {
  margin: 0 5px;
  opacity: 0.5;
}
